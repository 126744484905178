import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex items-center" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Address",
  props: {
    sm: Boolean,
    xs: Boolean,
    address: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const { success } = useNotifications();
    const { copy } = useClipboard();
    const { t } = useLang();
    function handleCopy() {
      copy(props.address).then(() => {
        success({
          title: t("connect.addressCopied")
        });
      });
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass(["tracking-tight", { "text-xs": __props.xs, "text-sm": __props.sm }])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        _createElementVNode("div", {
          class: "cursor-pointer text-gray-500 pl-2 pt-1 hover:text-blue-500",
          onClick: handleCopy
        }, [
          _createVNode(_component_BaseIcon, {
            name: "copy-plain",
            class: "w-4 h-4"
          })
        ])
      ]);
    };
  }
});
