"use strict";
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h-8" };
const _hoisted_2 = { class: "text-left pl-3" };
const _hoisted_3 = { class: "flex items-center" };
const _hoisted_4 = { class: "text-left" };
const _hoisted_5 = { class: "text-left" };
const _hoisted_6 = { class: "flex items-center justify-end" };
const _hoisted_7 = { class: "text-left" };
const _hoisted_8 = { class: "text-left" };
const _hoisted_9 = /* @__PURE__ */ _createElementVNode("th", null, null, -1);
export function render(_ctx, _cache) {
  const _component_CommonInfoTooltip = _resolveComponent("CommonInfoTooltip");
  return _openBlock(), _createElementBlock("thead", null, [
    _createElementVNode("tr", _hoisted_1, [
      _createElementVNode("th", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t("trade.time")) + " ", 1),
          _createVNode(_component_CommonInfoTooltip, {
            class: "ml-2",
            tooltip: _ctx.$t("trade.timestamp_tooltip")
          }, null, 8, ["tooltip"])
        ])
      ]),
      _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t("walletHistory.transfers.type")), 1),
      _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("walletHistory.transfers.asset")), 1),
      _createElementVNode("th", null, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("walletHistory.transfers.amount")), 1),
          _createVNode(_component_CommonInfoTooltip, {
            class: "ml-2",
            tooltip: _ctx.$t("walletHistory.transfers.amountTooltip")
          }, null, 8, ["tooltip"])
        ])
      ]),
      _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t("walletHistory.transfers.origin")), 1),
      _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("walletHistory.transfers.destination")), 1),
      _hoisted_9
    ])
  ]);
}
